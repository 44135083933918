import { ItauBoletoResponse, JetServiceSlipBody } from '~/server/payments/types/BoletoTypes';

export default function SlipTransform(itauSlip: ItauBoletoResponse): JetServiceSlipBody {
  const individualBoleto = itauSlip.dado_boleto.dados_individuais_boleto[0];
  function formatLinhaDigitavel(linha: string): string {
    if (linha.length !== 47) {
      throw new Error('A linha digitável deve ter 47 caracteres.');
    }

    const parte1 = `${linha.slice(0, 5)}.${linha.slice(5, 10)} `;
    const parte2 = `${linha.slice(10, 15)}.${linha.slice(15, 20)} `;
    const parte3 = `${linha.slice(20, 25)}.${linha.slice(25, 30)} `;
    const parte4 = `${linha.slice(30, 35)}.${linha.slice(35, 40)} `;
    const parte5 = linha.slice(40, 47);

    return parte1 + parte2 + parte3 + parte4 + parte5;
  }

  const BoletoSDTJSON = {
    ImagemBase64: '',
    ReciboID: '00000000-0000-0000-0000-000000000000',
    EmpresaID: '00000000-0000-0000-0000-000000000000',
    ReciboIdentificador: '',
    InstituicaoFinanceiraID: '00000000-0000-0000-0000-000000000000',
    ReciboEstagio: '',
    MeioPagamento: '00000000-0000-0000-0000-000000000000',
    PrazoPagamentoID: '00000000-0000-0000-0000-000000000000',
    Beneficiario: 'ESPACIAL SUPR. DE ESCRITORIO E INF. LTDA',
    BeneficiarioDocumentoIdentificador: '53.700.159/0001-85',
    BeneficiarioTelefone: '',
    BeneficiarioEnderecoCompleto: 'R DIANOPOLIS, 1010, VILA PRUDENTE, SAO PAULO, SP, 3126007',
    BeneficiarioEndereco: 'R DIANOPOLIS',
    BeneficiarioEnderecoNumero: '1010',
    BeneficiarioEnderecoBairro: 'VILA PRUDENTE',
    BeneficiarioEnderecoCidade: 'SAO PAULO',
    BeneficiarioEnderecoCEP: '3126007',
    DataDocumento: itauSlip.dado_boleto.data_emissao,
    NrDocumento: individualBoleto.numero_nosso_numero,
    Especie: 'DM',
    EspecieDOC: '',
    UsoBanco: '',
    Carteira: itauSlip.dado_boleto.codigo_carteira,
    QtdMoeda: '',
    Instrucoes: [{ Instrucoes: 'Sr. Caixa, não receber após o vencimento.', LocalPagamento: '', Identificador: 'Boleto' }],
    LocalPagamento: 'PAGÁVEL EM QUALQUER BANCO ATÉ O VENCIMENTO',
    DataProcedimento: itauSlip.dado_boleto.data_emissao,
    Identificador: '',
    Vencimento: individualBoleto.data_vencimento,
    Agencia_CodigoBeneficiario: '341 56 75350-2',
    NossoNumero: individualBoleto.numero_nosso_numero,
    ValorDocumento: Number(individualBoleto.valor_titulo).toFixed(2),
    CodigoCliente: '40578',
    Descontos: '0.00',
    OutrasDeducoes: '0.00',
    MoraMultaJuros: '0.00',
    OutrosAcrecimos: '0.00',
    ValorCobrado: '0.00',
    Pagador: itauSlip.dado_boleto.pagador.pessoa.nome_pessoa,
    PagadorDocumentoIdentificador:
      itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.codigo_tipo_pessoa === 'J'
        ? itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica
        : itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_pessoa_fisica,
    PagadorLogradouro: itauSlip.dado_boleto.pagador.endereco.nome_logradouro.split(',')[0],
    PagadorNumero: itauSlip.dado_boleto.pagador.endereco.nome_logradouro.split(',')[1],
    PagadorBairro: itauSlip.dado_boleto.pagador.endereco.nome_bairro,
    PagadorCEP: itauSlip.dado_boleto.pagador.endereco.numero_CEP,
    PagadorCidade: itauSlip.dado_boleto.pagador.endereco.nome_cidade,
    PagadorComplemento: '"',
    PagadorEstado: itauSlip.dado_boleto.pagador.endereco.sigla_UF,
    Origem: 'Boleto',
    Estagio: '',
    ContaFinanceiraID: '00000000-0000-0000-0000-000000000000',
    ReciboReferencia: '',
    ReciboTipoReferencia: '',
    ReciboCodigoBanco: '341',
    ReciboLinhaDigitavel: formatLinhaDigitavel(itauSlip.dado_boleto.dados_individuais_boleto[0].numero_linha_digitavel),
    BancoImg: '',
    ReciboCodigoBancoCompleto: '341-7',
    PrazoPagamento: [
      {
        PrazoPagamentoDias: '',
        PrazoPagamentoValor: Number(individualBoleto.valor_titulo).toFixed(2),
      },
    ],
  };

  return {
    BoletoSDTJSON: JSON.stringify(BoletoSDTJSON),
  };
}
