







import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SvgImage',
  props: {
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    tag: {
      type: String,
      default: 'span',
    },
    label: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'none',
    },
  },
  computed: {
    DynamicComponent() {
      return () => import(`@/icons/${this.icon}.vue`);
    }
  }
});
