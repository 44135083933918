import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

export class JetPaymentApiService {
  public AxiosJetPaymentInstance: AxiosInstance;

  constructor() {
    const customerToken = Cookies.get('vsf-customer');

    this.AxiosJetPaymentInstance = axios.create({
      baseURL: process.env.JETPAYMENT_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${customerToken ?? ''}`,
      },
    });
  }
}

export default JetPaymentApiService;
