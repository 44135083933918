import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { UseContextReturn } from '~/types/core';

export const getProductListCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const query = `
      query GetProductList($search: ProductAttributeFilterInput!, $pageSize: Int!, $currentPage: Int!, $sort: ProductAttributeSortInput!) {
        products(filter: $search, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
          items {
            sku
            name
            url_path
            url_rewrites {
              url
            }
            description {
              html
            }
            short_description {
              html
            }
            price_range {
              minimum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
              maximum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
            }
            image {
              url
            }
            small_image {
              url
            }
            thumbnail {
              url
            }
            media_gallery {
              url
            }
            stock_status
            categories {
              uid
              name
              url_path
            }
            ... on SimpleProduct {
              flagtext
              flagcolor
              flagposition
            }
            upsell_products {
              sku
              name
            }
            crosssell_products {
              sku
              name
            }
            related_products {
              sku
              name
            }
          }
        }
      }`;

    const buildSearchFilter = () => {
      const filter: Record<string, any> = {};

      if (searchParams.search) {
        const isSku = /^\d+$/.test(searchParams.search);
        if (isSku) {
          filter.sku = { eq: searchParams.search };
        } else {
          filter.name = { match: searchParams.search };
        }
      }

      if (searchParams.categoryId) {
        filter.category_id = { in: searchParams.categoryId };
      }

      if (searchParams.categoryUid) {
        filter.category_uid = { eq: searchParams.categoryUid };
      }

      if (searchParams.manufacturer) {
        filter.manufacturer = { eq: searchParams.manufacturer };
      }

      if (searchParams.sku) {
        filter.sku = { in: searchParams.sku };
      }

      return filter;
    };

    const queryVariables = {
      search: buildSearchFilter(),
      pageSize: searchParams.pageSize || 20,
      currentPage: searchParams.currentPage || 1,
      sort: searchParams.sort || { relevance: 'DESC' },
    };

    const { data }: any = await context.app.$vsf.$magento.api.customQuery({ query, queryVariables });

    return data?.products ?? null;
  },
};
