import { readonly, ref, useContext } from "@nuxtjs/composition-api";
import { storeToRefs } from "pinia";
import { useUserAddress } from "~/composables";
import {
  findUserAddressIdenticalToSavedCartAddress
} from "~/helpers/checkout/address";
import type { CustomerAddress } from "~/modules/GraphQL/types";
import useBilling from "~/modules/checkout/composables/useBilling";
import useCart from "~/modules/checkout/composables/useCart";
import { useCheckoutStore } from "~/stores/checkout";
import type { UseMakeOrderErrors, UseMakeOrderInterface } from "./useMakeOrder";

export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart } = useCart();
  const context = useContext();
  const { save: saveBilling } = useBilling();
  const { load: loadUserAddress } = useUserAddress();

  const checkoutStore = useCheckoutStore();

  const { selectedShippingMethod } = storeToRefs(checkoutStore);



  const definirMetodoPagamento = async (BEARER_TOKEN: string, code: string) => {
    try {
      const apiUrl = '/order/payment-method';
      return await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          code,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const definirFormaEntrega = async (BEARER_TOKEN: string) => {
    try {
      const apiUrl = '/order/shipping-method';
      return await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          carrier_code: selectedShippingMethod.value.carrier_code,
          method_code: selectedShippingMethod.value.method_code,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const definirEnderecoFaturamento = async () => {
    try {
      if (cart.value.shipping_addresses.length === 0) return;
      const customer = await loadUserAddress();

      const address = cart.value.shipping_addresses[0];
      const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
        customer.addresses,
        address,
      );

      const billingDetailsData = {
        billingDetails: {
          firstname: address.firstname,
          lastname: address.lastname,
          street: address.street?.[0],
          apartment: address.street?.[1],
          city: address.city,
          region: (address as CustomerAddress)?.region?.region_code ?? (address as any).region.code,
          country_code: (address as CustomerAddress)?.country_code ?? (address as any).country.code,
          postcode: address.postcode,
          telephone: address.telephone,
          customerAddressId: String(userAddressIdenticalToSavedCartAddress.id),
          sameAsShipping: true,
          save_in_address_book: false,
        },
      };
      await saveBilling(billingDetailsData);
    } catch (error) {
      console.error(error);
    }
  };

  const definirEnderecoEntrega = async (BEARER_TOKEN: string) => {
    try {
      if (cart.value.shipping_addresses.length > 0) return;

      const shippingAddresses = cart.value.shipping_addresses.map((address) => ({
        address: {
          firstname: address.firstname,
          lastname: address.lastname,
          street: address.street,
          city: address.city,
          company: address.company,
          region: address.region.code,
          region_id: address.region.region_id,
          postcode: address.postcode,
          telephone: address.telephone,
          country_code: address.country.code,
        },
      }));

      const apiUrl = '/order/shipping-address';
      return await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          shipping_addresses: shippingAddresses,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const criarPedido = async (BEARER_TOKEN: string) => {
    try {
      const apiUrl = '/order/create';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
        }),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const make = async (code: string): Promise<any> => {
    const BEARER_TOKEN = context.$vsf.$magento.config.state.getCustomerToken();

    await Promise.all([
      definirMetodoPagamento(BEARER_TOKEN, code),
      definirFormaEntrega(BEARER_TOKEN),
      definirEnderecoFaturamento(),
      definirEnderecoEntrega(BEARER_TOKEN),
    ]);

    return criarPedido(BEARER_TOKEN);
  };

  return { make, error: readonly(error), loading: readonly(loading) };
}

export * from './useMakeOrder';
export default useMakeOrder;
