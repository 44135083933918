
















import { SfButton, SfIcon } from '@storefront-ui/vue';

import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderButton',
  components: {
    SfButton,
    SfIcon,
  },
  props: {
    showBackToTop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return {
      scrollToTop,
    };
  },
});
