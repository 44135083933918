














































































































































import {
SfBadge,
SfButton,
SfHeader,
SfOverlay,
} from '@storefront-ui/vue';

import {
Ref,
computed,
defineComponent,
inject,
onMounted,
provide,
ref,
useContext,
useFetch,
useRouter,
watch,
} from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import HeaderNavigation from '~/components/JetUi/organisms/Header/HeaderNavigation.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {
useUiHelpers,
useUiState,
} from '~/composables';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import { Products } from '~/modules/GraphQL/types';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { useCartStore } from '~/stores/cart';
import { useTopBar } from './TopBar/useTopBar';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SkeletonLoader,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/JetUi/organisms/searchResults/SearchResult.vue'
    ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } = useUiState();
    const { getProductListOpensearch } = useProduct();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, user, load } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { username, updateUsername } = inject<{ username: Ref<string>; updateUsername:(name: string) => void; }>('username');
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const cartStore = useCartStore();
    const loading = ref(false);

    const searchTerms = ref('');
    let debounceTimeout: any = null;

    const updateSearchTerms = async (term: any) => {
      loading.value = true;
      searchTerms.value = term;
      productSearchResults.value = null;

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(async () => {
        if (term) {
          const formattedTerm = term.charAt(0).toUpperCase() + term.slice(1);

          const productList: Products = await getProductListOpensearch({
            pageSize: 200,
            search: formattedTerm,
          }) as Products;

          productSearchResults.value = productList!.items;
        }

        loading.value = false;
      }, 300);
    };

    provide('searchTerms', {
      searchTerms, updateSearchTerms, productSearchResults, loading,
    });

    const isSearchOpen = ref(false);
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));

    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      categoryTree.value = categoryTree.value.reduce((acc: CategoryTree[], current) => {
        const existing = acc.find((item) => item.name === current.name);

        if (existing) {
          if (current.children.length > 0) {
            const index = acc.indexOf(existing);
            acc.splice(index, 1, current);
          }
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

      categoryTree.value.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        await loadWishlistItemsCount();
      }
      await load();
      cartStore.setUserInfo(user.value);
      updateUsername(isAuthenticated.value && user.value ? `${user.value?.firstname}` : '');
    });

    watch(user, async (value) => {
      if (value) {
        updateUsername(isAuthenticated.value && value ? `${value?.firstname}` : '');
      }
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      username,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
    };
  },
});
