import { defineStore } from 'pinia';

export const useCartStore = defineStore('cartUserInfo', {
  state: () => ({
    userInfo: null,
  }),
  actions: {
    setUserInfo(userInfo) {
      this.userInfo = userInfo;
    },
  },
});
