






















import {
defineComponent, ref,
useRouter,
} from '@nuxtjs/composition-api';
import { SfTopBar } from '@storefront-ui/vue';
import SfNotification from '~/components/JetUi/molecules/SfNotification/SfNotification.vue';

export default defineComponent({
  components: {
    SfTopBar,
    SfNotification,
  },
  setup() {
    const visibilityCoupon = ref(false);
    const couponCode = ref('ESP10');
    const router = useRouter();

    // const copyCoupon = () => {
    //   navigator.clipboard.writeText(couponCode.value).catch((err) => console.error('Failed to copy:', err));
    //   visibilityCoupon.value = true;

    //   setTimeout(() => {
    //     visibilityCoupon.value = false;
    //   }, 3000);
    // };

    const redirectToCouponPage = () => {
      router.push('/cupons');
    };

    return {
      couponCode,
      visibilityCoupon,
      redirectToCouponPage,
    };
  },
});
