




























import { focus } from '~/components/JetUi/utils/directives';

export default {
  name: 'JetUiButton',
  directives: {
    focus,
  },
  inject: ['SfLink'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit', 'reset'].includes(value),
    },
    link: {
      type: [String, Object],
      default: null,
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
  },
  methods: {
    linkActive(link, disabled) {
      return link && disabled;
    },
    buttonActive(link, disabled) {
      return !link && disabled;
    },
  },
};
