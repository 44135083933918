




























import type { NuxtError } from '@nuxt/types';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';

export default defineComponent({
  components: {
    JetUiButton,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const errorMessages = [
      {
        statusCode: 404,
        title: 'Página não encontrada',
        message: 'A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível.',
      },
      {
        statusCode: 500,
        title: 'Erro interno do servidor',
        message: 'Ocorreu um erro inesperado no servidor. Por favor, tente novamente mais tarde.',
      },
      {
        statusCode: 403,
        title: 'Acesso negado',
        message: 'Você não tem permissão para acessar esta página.',
      },
      {
        statusCode: 400,
        title: 'Requisição inválida',
        message: 'A requisição foi inválida ou não pode ser processada.',
      },
      {
        statusCode: null,
        title: 'Ocorreu um erro',
        message: 'Algo deu errado. Por favor, tente novamente.',
      },
    ];

    const currentError = computed(() => {
      const error = props.error as NuxtError;
      return (
        errorMessages.find((err) => err.statusCode === error.statusCode)
        || errorMessages.find((err) => err.statusCode === null)
      );
    });

    return {
      currentError,
    };
  },
});
