export function useValidator() {
  const validateCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
    }

    const calculateDigit = (cpf: string, length: number): number => {
      let sum = 0;
      for (let i = 0; i < length; i++) {
        sum += Number.parseInt(cpf.charAt(i)) * (length + 1 - i);
      }
      const remainder = (sum * 10) % 11;
      return remainder === 10 || remainder === 11 ? 0 : remainder;
    };

    const firstDigit = calculateDigit(cpf, 9);
    if (firstDigit !== Number.parseInt(cpf.charAt(9))) {
      return false;
    }

    const secondDigit = calculateDigit(cpf, 10);
    return secondDigit === Number.parseInt(cpf.charAt(10));
  };

  const validateCNPJ = (cnpj: String): boolean => {
    cnpj = cnpj.replace(/\W+/g, '');

    if (cnpj.length != 14) return false;

    let sum = sumMultipliers(cnpj, 12, 1);
    const firstDigit = sum < 2 ? 0 : 11 - sum;

    sum = sumMultipliers(cnpj, 13, 0);
    const secondDigit = sum < 2 ? 0 : 11 - sum;

    return firstDigit == Number.parseInt(cnpj[cnpj.length - 2]) && secondDigit == Number.parseInt(cnpj[cnpj.length - 1]);
  };

  const sumMultipliers = (cnpj: String, length: number, shift: number) => {
    const multipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;

    for (let i = 0; i < length; i++) {
      sum += (cnpj[i].charCodeAt(0) - 48) * multipliers[i + shift];
    }

    return sum % 11;
  };

  return {
    validateCPF,
    validateCNPJ,
  };
}

export default useValidator;
