export interface PaymentMethodInterface {
  label: string;
  value: string;
}

export const RedeErrorMessages: Record<string, string> = {
  '111': "Cartão Recusado",
  '109': "Cartão Inexistente",
  '124': "Não autorizado, contate a Rede",
  "37": "Não autorizado, dados inválidos",
};
