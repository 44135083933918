








































import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon, SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeaderNavigationItem',
  components: {
    SfLink,
    SfIcon,
  },
  props: {
    newPage: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    isMoreOption: {
      type: Boolean,
      default: false,
    },
  },
});
