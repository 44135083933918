import {
  computed, reactive, ref, watch,
} from '@nuxtjs/composition-api';
import crypto from 'crypto';
import { PaymentCardInterface, UsePaymentCardInterface } from '~/composables/usePaymentCard/usePaymentCard';
import { RedeErrorMessages } from '~/modules/checkout/types';
import JetPaymentApiService from '~/server/payments/JetPaymentApiInstance';
import { useUser } from '..';

const state = reactive<PaymentCardInterface>({
  isPaymentCardModalOpen: false,
  selectedPaymentCard: null,
  selectedInstallment: null,
});

export function usePaymentCard(): UsePaymentCardInterface {
  const { user } = useUser();
  const loading = ref(true);
  const selectedPaymentCard = computed(() => state.selectedPaymentCard);
  const selectedInstallment = computed(() => state.selectedInstallment);
  const paymentCards = ref<any[]>([]);
  const keyHex = process.env.SECRET_KEY || '';
  const key = Buffer.from(keyHex, 'hex');

  const togglePaymentCardModal = () => {
    state.isPaymentCardModalOpen = !state.isPaymentCardModalOpen;
  };

  const decrypt = (encryptedData: string, iv: string): string => {
    if (!iv || !encryptedData) {
      return '';
    }
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, Buffer.from(iv, 'hex'));
    let decrypted = decipher.update(Buffer.from(encryptedData, 'hex'));
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString('utf8');
  };

  const fetchPaymentCards = async () => {
    try {
      const queryString = new URLSearchParams({
        email: user.value.email,
      }).toString();

      const apiUrl = `/payment-card/all?${queryString}`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      paymentCards.value = data;
    } catch {
      paymentCards.value = [];
    }
  };

  const resetPaymentCardData = () => {
    state.selectedPaymentCard = null;
    state.selectedInstallment = null;
  };

  const deletePaymentCard = async (id: string) => {
    try {
      const apiUrl = `/payment-card/${id}`;
      await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      fetchPaymentCards();
    } catch (error) {
      console.error(error);
    }
  };

  const createPaymentCard = async (form: any) => {
    try {
      const apiUrl = '/payment-card';
      await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.value.email,
          ...form,
        }),
      });
      fetchPaymentCards();
    } catch (error) {
      console.error(error);
    }
  };

  const payOrder = async (order_id: string) => {
    const api_request = {
      capture: true,
      kind: 'credit',
      reference: order_id,
      amount: Math.floor(selectedInstallment.value.amount * 100),
      cardholderName: decrypt(selectedPaymentCard.value.name, selectedPaymentCard.value.cvi),
      cardNumber: decrypt(selectedPaymentCard.value.number, selectedPaymentCard.value.cvi).replace(/ /g, ''),
      expirationMonth: Number.parseInt(decrypt(selectedPaymentCard.value.month, selectedPaymentCard.value.cvi)),
      expirationYear: Number.parseInt(decrypt(selectedPaymentCard.value.year, selectedPaymentCard.value.cvi)),
      securityCode: decrypt(selectedPaymentCard.value.cvv, selectedPaymentCard.value.cvi),
      installments: selectedInstallment.value.number_installments,
    };
    const body = {
      data: api_request,
      amount: selectedInstallment.value.amount,
    };
    const JetPaymentApi = new JetPaymentApiService().AxiosJetPaymentInstance;

    try {
      const response = await JetPaymentApi.post('/rede/transactions', body, { params: { orderId: order_id, username: user.value.firstname, email: user.value.email } });
      return response.data;
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        const errorMessage = RedeErrorMessages[error.response.data.errorCode];
        if (errorMessage) {
          return errorMessage;
        }
      }
      console.error(error);
    }
  };

  const selectCard = (card) => {
    state.selectedPaymentCard = card;
  };

  const selectInstallment = (installment) => {
    state.selectedInstallment = installment;
  };

  watch(user, async () => {
    if (loading.value) {
      await fetchPaymentCards();
      loading.value = false;
    }
  });

  return {
    isPaymentCardModalOpen: computed(() => state.isPaymentCardModalOpen),
    payOrder,
    selectCard,
    createPaymentCard,
    deletePaymentCard,
    togglePaymentCardModal,
    paymentCards,
    loading,
    decrypt,
    selectInstallment,
    selectedInstallment,
    selectedPaymentCard,
    fetchPaymentCards,
    resetPaymentCardData,
  };
}

export default usePaymentCard;
export * from './usePaymentCard';
