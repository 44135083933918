import {
  computed, onMounted, ref, useContext, useRouter,
} from '@nuxtjs/composition-api';
import { defineStore, storeToRefs } from 'pinia';
import {
  useAddresses, useCountrySearch, usePaymentCard, useUser,
} from '~/composables';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import {
  CheckoutAddressForm,
  addressFromApiToForm,
  findUserAddressIdenticalToSavedCartAddress,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import type { Country, Customer, CustomerAddress } from '~/modules/GraphQL/types';
import useBilling from '~/modules/checkout/composables/useBilling';
import useShipping from '~/modules/checkout/composables/useShipping';
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import addressGetter from '~/modules/customer/getters/addressGetter';
import userCheckoutAddressesGetters from '~/modules/customer/getters/userCheckoutAddressesGetter';

const initialState = {
  STEPS: [
    { title: 'Shipping', Group: 'Detalhes de Entrega', component: 'Shipping' },
    { title: 'Payment', Group: 'Detalhes de Pagamento', component: 'Payment' },
  ],
  checkoutSteps: { shipping: false, billing: false },
  loading: false,
  selectedPaymentMethod: null,
  addresses: [],
  currentShippingAddressId: null,
  currentBillingAddressId: null,
  shippingMethods: [],
  diffFromShipping: false,
  selectedShippingMethod: null,
  clientSummaryHeight: null,
  loadShippingMounted: false,
};

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    ...initialState,
  }),
  getters: {
    isAuthenticated: (): boolean => {
      const { isAuthenticated } = useUser();
      return isAuthenticated.value;
    },
    user: (): Customer => {
      const { user, loading } = useUser();
      if (loading.value) return null;
      return user.value;
    },
    isShippingCompleted: (state): boolean => !!state.currentShippingAddressId && !!state.selectedShippingMethod,
    isPaymentCompleted: (state): boolean => {
      const { selectedPaymentCard, selectedInstallment } = usePaymentCard();
      return !!state.selectedPaymentMethod && (state.selectedPaymentMethod !== 'jetcred' || (selectedPaymentCard.value && selectedInstallment.value));
    },
  },
  actions: {
    checkoutStepsUpdate(key: string, value: boolean) {
      if (key in this.checkoutSteps) {
        this.checkoutSteps[key] = value;
      }
    },
    onLoading(value: boolean) {
      this.loading = value;
    },
    resetState(excludedKeys = []) {
      if (excludedKeys.length === 0) {
        this.$reset();
        return;
      }
      const preservedState = excludedKeys.reduce((acc, key) => {
        if (key in this.$state) {
          acc[key] = this.$state[key];
        }
        return acc;
      }, {});

      this.$patch({
        ...initialState,
        ...preservedState,
      });
    },
    setSelectedShippingMethod(method: any) {
      this.selectedShippingMethod = method;
    },
    setSelectedPaymentMethod(method: string) {
      this.selectedPaymentMethod = method;
    },
    async loadAddresses() {
      const { load } = useUserAddress();
      const updatedUserAddress = await load();
      this.addresses = userCheckoutAddressesGetters.getAddresses(updatedUserAddress);
    },
    setAddresses(addresses: CustomerAddress[]) {
      this.addresses = addresses;
    },
    setCurrentAddressId(originMethods: string, value: number) {
      if (originMethods === 'shipping') {
        this.currentShippingAddressId = value;
      } else {
        this.currentBillingAddressId = value;
      }
    },
    getCurrentAddressId(originMethods: string) {
      if (originMethods === 'shipping') {
        return this.currentShippingAddressId;
      }
      return this.currentBillingAddressId;
    },
  },
});

export function useCheckoutAddress() {
  const addressForm = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());

  return {
    addressForm,
  };
}

export function useCheckout(originMethods: string, doMounted = true) {
  const router = useRouter();
  const { app } = useContext();
  const checkoutStore = useCheckoutStore();

  const { load: loadShipping, save: saveShipping } = useShipping();

  const { save: saveBilling, load: loadBilling } = useBilling();

  const { isAuthenticated } = useUser();

  const loaded = ref(false);
  const user = ref<Customer | null>(null);

  const countries = ref<Country[]>([]);
  const country = ref<Country | null>(null);
  const isAddNewAddressFormVisible = ref(true);
  const zipCodeError = ref<string | null>(null);
  const { setAddresses, setCurrentAddressId, getCurrentAddressId } = checkoutStore;
  const {
    addresses, shippingMethods, selectedShippingMethod, diffFromShipping, currentBillingAddressId, currentShippingAddressId,
  } = storeToRefs(checkoutStore);
  const currentAddressId = computed<number | null>(() => getCurrentAddressId(originMethods));
  const previousCurrentAddressId = ref<number | null>(null);

  const addressForm = ref<CheckoutAddressForm | any>(getInitialCheckoutAddressForm());
  const shippingMethodLoading = ref(false);

  const useAddressesComposable = useAddresses();
  const { load: loadUserAddress, setDefaultAddress } = useUserAddress();
  const { load: loadCountries, search: searchCountry } = useCountrySearch();

  const hasSavedAddress = computed(() => {
    if (!isAuthenticated.value || !user.value) {
      return false;
    }
    return addresses.value.length > 0;
  });

  const loadAddresses = async () => {
    const updatedUserAddress = await loadUserAddress();
    setAddresses(userCheckoutAddressesGetters.getAddresses(updatedUserAddress));
    user.value = updatedUserAddress;
  };

  const verifyCurrentAddress = async () => {
    if (!addresses.value.some((item) => item.value === currentAddressId.value)) {
      setCurrentAddressId(originMethods, addresses.value[0].id);
    }
  };

  const countriesList = computed(() => addressGetter.countriesList(countries.value));

  const regionInformation = computed(() => addressGetter.regionList(country.value));

  const saveNewAddress = async (address: any) => {
    loaded.value = false;

    const regionCode = address.region;
    address.region = {
      region_id: regionInformation.value.find((region) => region.abbreviation === regionCode)?.id,
      region_code: regionCode as string,
    };

    await useAddressesComposable.save({ address });
    isAddNewAddressFormVisible.value = false;
    await loadAddresses();
    loaded.value = true;
  };

  const cancelAddNewAddress = () => {
    if (previousCurrentAddressId.value) {
      setCurrentAddressId(originMethods, previousCurrentAddressId.value);
    }
    isAddNewAddressFormVisible.value = false;
  };

  const handleDefaultAddress = async (addressId: number, isDefault: boolean) => {
    if (addressId !== null && isDefault) {
      const [chosenAddress] = userCheckoutAddressesGetters.getAddresses(user.value, { id: addressId });
      chosenAddress.default_billing = isDefault;
      if (chosenAddress) {
        await setDefaultAddress({ address: chosenAddress });
        user.value = await loadUserAddress(true);
      }
    }
  };

  const handleAddNewAddressBtnClick = () => {
    previousCurrentAddressId.value = currentAddressId.value;
    setCurrentAddressId(originMethods, null);
    addressForm.value = getInitialCheckoutAddressForm();
    isAddNewAddressFormVisible.value = true;
  };

  const handleAddressEdit = async (customerAddress: CustomerAddress) => {
    const id = customerAddress?.id;
    setCurrentAddressId(originMethods, id);
    if (id) {
      isAddNewAddressFormVisible.value = false;
    }
    addressForm.value = addressFromApiToForm(customerAddress);
    country.value = customerAddress.country_code ? await searchCountry({ id: customerAddress.country_code }) : null;
  };

  const changeAddressForm = (field: keyof CheckoutAddressForm, value: string) => {
    // antigo changeShippingDetails/changeBillingDetails
    addressForm.value[field] = value;
    setCurrentAddressId(originMethods, null);
  };

  const changeCountry = async (id: string) => {
    const newCountry = await searchCountry({ id });
    country.value = newCountry;
  };

  const fetchAddressByZipCode = async (zipCode: string) => {
    if (originMethods === 'billing') {
      zipCode = zipCode.replace(/\D/g, '');
    }

    if (zipCode.length < 8) {
      zipCodeError.value = null;
      return;
    }

    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
      const data = await response.json();

      if (data.erro) {
        zipCodeError.value = 'CEP não encontrado';
        return;
      }

      addressForm.value.street = data?.logradouro || '';
      addressForm.value.city = data?.localidade || '';
      addressForm.value.region = data?.uf || '';

      if (originMethods === 'billing') {
        changeAddressForm('region', addressForm.value.region);
      }

      zipCodeError.value = null;
    } catch (error) {
      console.error('Erro ao buscar endereço:', error);
      zipCodeError.value = 'Erro ao buscar endereço';
    }
  };

  const checkIfHasSameAddress = () => currentBillingAddressId.value === currentShippingAddressId.value;

  const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
    if (originMethods === 'billing') {
      await mergeItem('checkout', { billing: customerAddress });
    }

    shippingMethodLoading.value = true;
    handleAddressEdit(customerAddress);
    shippingMethodLoading.value = false;

    if (originMethods === 'billing') {
      addressForm.value.sameAsShipping = false;
      addressForm.value.customerAddressId = customerAddress.id;
      const regionCode = addressForm.value.region;
      addressForm.value.region = {
        region_id: regionInformation.value.find((region) => region.abbreviation === regionCode)?.id,
        region_code: regionCode as string,
      } as any;
      await mergeItem('checkout', { billing: addressForm });
      await saveBilling({ billingDetails: addressForm.value });
    }
  };

  const loadShippingCheckout = async () => {
    const [loadedAddressInfoBoundToCart, loadedUserAddress, loadedCountries] = await Promise.all([
      originMethods === 'shipping' ? loadShipping() : loadBilling(),
      loadUserAddress(),
      loadCountries(),
    ]);
    const [defaultAddress = null] = userCheckoutAddressesGetters.getAddresses(loadedUserAddress, { default_shipping: true });
    const wasAddressAlreadySetOnCart = Boolean(loadedAddressInfoBoundToCart);

    if (wasAddressAlreadySetOnCart) {
      const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
        loadedUserAddress?.addresses,
        loadedAddressInfoBoundToCart,
      );
      handleSetCurrentAddress({ ...loadedAddressInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id });
    } else if (defaultAddress) {
      handleSetCurrentAddress(defaultAddress);
    }
    if (addressForm.value?.country_code) {
      country.value = await searchCountry({ id: addressForm.value.country_code });
    }
    user.value = loadedUserAddress;
    countries.value = loadedCountries;
    if (originMethods === 'shipping' && currentAddressId.value) {
      const shippingAddress = loadedAddressInfoBoundToCart as any;
    }

    diffFromShipping.value = false;
    if (!checkIfHasSameAddress()) {
      diffFromShipping.value = true;
    }

    loaded.value = true;
  };

  onMounted(async () => {
    if (!doMounted) return;

    if (!isAuthenticated.value) {
      isAddNewAddressFormVisible.value = false;
    }
    loadShippingCheckout();
  });

  return {
    user,
    countries,
    addresses,
    isAuthenticated,
    loadAddresses,
    hasSavedAddress,
    countriesList,
    regionInformation,
    loaded,
    cancelAddNewAddress,
    saveNewAddress,
    handleDefaultAddress,
    handleAddNewAddressBtnClick,
    changeAddressForm,
    changeCountry,
    fetchAddressByZipCode,
    loadShipping,
    loadBilling,
    saveShipping,
    saveBilling,
    currentAddressId,
    addressForm,
    isAddNewAddressFormVisible,
    handleAddressEdit,
    zipCodeError,
    country,
    searchCountry,
    app,
    router,
    handleSetCurrentAddress,
    verifyCurrentAddress,
    shippingMethods,
    loadShippingCheckout,
  };
}
