var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.$props.link ? _vm.link : 'button',_vm._g(_vm._b({tag:"component",class:[
    'jetui-default-button',
    _vm.$data.class,
    _vm.$data.staticClass,
    'sf-button',
    {
      'is-disabled--button': _vm.buttonActive(
        _vm.$props.link,
        _vm.$props.disabled
      ),
      'is-disabled--link': _vm.linkActive(_vm.$props.link, _vm.$props.disabled),
    } ],style:([_vm.$data.style, _vm.$data.staticStyle]),attrs:{"aria-disabled":_vm.$props.disabled,"link":_vm.$props.link,"type":_vm.$props.type,"aria-label":_vm.$props.ariaLabel}},'component',_vm.$data.attrs,false),!_vm.$props.disabled ? _vm.$listeners : {}),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }