








































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfIcon } from '@storefront-ui/vue';
import { PropType } from 'vue';

export default defineComponent({
  name: 'SfNotification',
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'confirm'>,
      default: 'secondary',
    },
    color: {
      type: String,
      required: false,
    },
    position: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'top',
    },
  },
  computed: {
    icon(): string {
      switch (this.type) {
        case 'success':
          return 'added_to_cart';
        case 'danger':
          return 'info_shield';
        case 'confirm':
          return '';
        default:
          return 'info_circle';
      }
    },
    colorClass(): string {
      return `color-${this.type}`;
    },
    positionClass(): string {
      return `position-${this.position}`;
    },
  },
  methods: {
    actionHandler() {
      this.$emit('click:action');
    },
    closeHandler() {
      this.$emit('click:close');
    },
  },
});
