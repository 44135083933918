import JetPaymentApiService from './JetPaymentApiInstance';
import { ItauBoletoQueryRequest, ItauBoletoResponse } from './types/BoletoTypes';

class BoletoService {
  // Emissão de boleto
  async emitirBoleto(data: any, orderId: string, user:any): Promise<ItauBoletoResponse> {
    try {
      const JetPaymentApi = new JetPaymentApiService().AxiosJetPaymentInstance;
      const response = await JetPaymentApi.post('itau/boleto/transactions', data, { params: { orderId, username: user.value.firstname, email: user.value.email } });
      return response.data.data;
    } catch (error) {
      throw new Error(`Erro ao emitir Boleto: ${error}`);
    }
  }

  // consultar status de pagamento
  async recuperarBoleto(orderId: string): Promise<any> {
    try {
      const JetPaymentApi = new JetPaymentApiService().AxiosJetPaymentInstance;

      const response = await JetPaymentApi.get('itau/boleto/transactions', { params: { orderId } });
      console.log('response', response);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao consultar boleto: ${error}`);
    }
  }

  createQueryString = (data: ItauBoletoQueryRequest): string => {
    const queryObject: Record<string, string> = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        queryObject[key] = value.toString();
      }
    });
    return new URLSearchParams(queryObject).toString();
  };
}

export default new BoletoService();
