export function useFile() {
  function fileToBase64(file: File): Promise<{ base64: string, mimeType: string }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve({
          base64: base64String,
          mimeType: file.type,
        });
      });

      reader.onerror = () => {
        reject(new Error('Failed to read file'));
      };

      reader.readAsDataURL(file);
    });
  }

  async function filesToBase64(files: File[]): Promise<Array<{ filename: string, base64: string, mimeType: string }>> {
    const base64Promises = files.map(async (file) => {
      const { base64, mimeType } = await fileToBase64(file);
      return {
        filename: file.name,
        base64,
        mimeType,
      };
    });

    return Promise.all(base64Promises);
  }

  return {
    filesToBase64,
  };
}
