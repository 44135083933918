








































import {
computed,
defineComponent,
onBeforeUnmount,
onMounted,
provide,
ref,
useFetch,
useRoute,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import FloatWppButton from '~/components/JetUi/atoms/FloatButtons/FloatWppButton.vue';
import HeaderButton from '~/components/JetUi/atoms/FloatButtons/HeaderButton.vue';
import TopBar from '~/components/JetUi/atoms/topbar/TopBar.vue';
import MenuDrawer from '~/components/JetUi/organisms/Menu/MenuDrawer.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useUiState } from '~/composables';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    TopBar,
    FloatWppButton,
    HeaderButton,
    MenuDrawer,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/JetLogin/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const header = ref(null);
    const headerHeight = ref(190);
    const route = useRoute();
    const username = ref('');
    const hasScrolled = ref(false);
    const categoryTree = ref<CategoryTree[]>([]);
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const updateUsername = (name: string) => {
      username.value = name;
    };

    const getCustomLinks = () => [
      { id: '1-link', name: '2° VIA BOLETO', link: 'hhttp://espacial.web22f21.kinghost.net/boleto/gestaocontratos.ww_acesso.aspx' },
      { id: '2-link', name: 'CONTRATO', link: 'https://portalcliente.espacial.com.br/login.aspx' },
      { id: '3-link', name: 'ORÇAMENTO', link: '/orcamento' },
      { id: '4-link', name: 'CONTATO', link: '/fale-conosco' },
      { id: '6-link', name: 'CUPONS', link: '/cupons' },
    ];

    const styledHeader = computed(() => (hasScrolled.value
      ? { transition: 'transform 0.5s ease-in-out' }
      : { transition: 'transform 0.1s ease-in-out' }));

    provide('username', { username, updateUsername });

    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, toggleMobileMenu, isMobileMenuOpen,
    } = useUiState();

    const updateHeaderHeight = () => {
      hasScrolled.value = window.scrollY > 10;
      if (header.value) {
        if (hasScrolled.value) {
          headerHeight.value = window.innerWidth < 600 ? header.value.offsetHeight - window.innerHeight * 0.18 : header.value.offsetHeight - 40;
        } else {
          headerHeight.value = header.value.offsetHeight;
        }
      }
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      categoryTree.value = categoryTree.value.reduce((acc: CategoryTree[], current) => {
        const existing = acc.find((item) => item.name === current.name);

        if (existing) {
          if (current.children.length > 0) {
            const index = acc.indexOf(existing);
            acc.splice(index, 1, current);
          }
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

      categoryTree.value.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    });

    onMounted(() => {
      updateHeaderHeight();
      window.addEventListener('resize', updateHeaderHeight);
      window.addEventListener('scroll', updateHeaderHeight);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateHeaderHeight);
      window.removeEventListener('scroll', updateHeaderHeight);
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      header,
      headerHeight,
      hasScrolled,
      styledHeader,
      getCustomLinks,
      toggleMobileMenu,
      isMobileMenuOpen,
      categoryTree,
    };
  },
});
