import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const query = `
    query GetProductDetails($search: ProductAttributeFilterInput!, $pageSize: Int!, $currentPage: Int!) {
      products(filter: $search, pageSize: $pageSize, currentPage: $currentPage) {
        items {
          sku
          name
          url_key
          url_path
          rating_summary
          review_count
          meta_description
          meta_keyword
          meta_title
          manufacturer
          reviews {
            items {
                average_rating
                created_at
                nickname
                summary
                text
            }
          }
          url_rewrites {
            url
          }
          description {
            html
          }
          short_description {
            html
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
            }
            maximum_price {
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
            }
          }
          image {
            url
            position
            disabled
            label
          }
          small_image {
            url
            position
            disabled
            label
          }
          thumbnail {
            url
            position
            disabled
            label
          }
          media_gallery {
            url
            position
            disabled
            label
          }
          stock_status
          categories {
            uid
            name
            url_suffix
            url_path
          }
          ... on SimpleProduct {
            flagtext
            flagcolor
            flagposition
          }
          upsell_products {
            sku
            name
          }
          crosssell_products {
            sku
            name
          }
          options_container
          related_products {
            sku
            name
          }
        }
      }
    }
  `;
    const queryVariables = {
      search: searchParams.filter,
      pageSize: searchParams.pageSize || 20,
      currentPage: searchParams.currentPage || 1,
    };
    const { data }: any = await context.app.$vsf.$magento.api.customQuery({ query, queryVariables });

    return data?.products ?? null;
  },
};
